.grid {
  height: 100vh;
}
.content {
  flex: 1;
  padding: 0;
  margin-top: 65px;
}
.content_wrapper {
  padding: 1rem;
  max-width: 100vw;
}
@media screen and (min-width: 960px) {
  .grid {
    height: 100vh;
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-areas: "item1 item2";
  }
  .content {
    flex: 1;
    padding: 0;
    margin-top: 0;
  }
  .content_wrapper {
    padding: 2rem;
  }
}

.wrapper {
  padding: 2rem 3rem;
  background-color: rgba(255, 255, 255, .75);
  margin-bottom: 2rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 5px;
}

.box {
  padding: 1rem 1rem 0;
}
.boxbutton {
  margin: 0 1rem 1rem;
}
.boxtitle{
  font-weight: 700;
}
.boxtext{
  display: block;
  display: -webkit-box;
  line-height: 1.4em;
  height: 5.6em;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.media {
  width: 100%;
  padding-top: 52%
}

.imgfluid {
  width: 100%;
  height: auto;
  position: relative;
}
.slide {
  position: relative;
}

.slidetitle {
  z-index: 10;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-color: rgba(255, 255, 255, .5);
  padding: 1rem;
}

.slidetext {
  margin: 0 auto;
  font-weight: 700;
}
img {
  max-width: 100%;
}

.youtube {
  max-width: 100%;
}
iframe {
  max-width: 100%;
  width: 100vw;
  height: 46vw;
}
.breadcrumb {
  font-size: 1rem;
  color: #262626;
}
